import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useMap } from "react-leaflet/hooks";
import L from "leaflet";
import "leaflet-easybutton/src/easy-button";
import "leaflet.geodesic";
import "leaflet.heightgraph";
import "leaflet.heightgraph/dist/L.Control.Heightgraph.min.css";
import {
    Bullseye as IconBuffer,
    GraphUp as IconElevation,
    HouseFill as IconHouse,
    Rulers as IconDistance,
    Textarea as IconArea,
    Trash3Fill as IconTrash,
} from "react-bootstrap-icons";
import { mapInteractionEnable, mapInteractionDisable } from "../utils";
import { alertService, elevationService } from "../services";

export function CustomActions() {
    const map = useMap();
    const circleFeatureGroup = L.featureGroup().addTo(map);
    let heightgraph;

    const [bufferCircleRadius, setBufferCircleRadius] = useState(50);
    const [isActionBufferCircleActive, setIsActionBufferCircleActive] =
        useState(false);

    useEffect(() => {
        if (isActionBufferCircleActive === true) {
            // @TODO: ensure that this bind is getting triggered first, so that stopPropagation can work
            map.on("click", (event) => {
                drawCircle(event, bufferCircleRadius);
            });
        }

        return () => {
            map.off("click");
        };
    }, [bufferCircleRadius, isActionBufferCircleActive]);

    useEffect(() => {
        // buttons will be added in the reverse order, first here is last on the map
        const actionDelete = createActionDelete();
        const actionBufferObjects = createActionBufferObjects();
        const actionBufferCircle = createActionBufferCircle();
        const actionHeightgraph = createActionHeightgraph();
        const actionArea = createActionArea();
        const actionPolyline = createActionPolyline();

        heightgraph = L.control.heightgraph({
            expandCallback: (expanded) => {
                if (false === expanded) {
                    heightgraph.remove();

                    // remove heightgraph's line
                    map.eachLayer((layer) => {
                        // ignore non-Geoman layers
                        if (undefined === layer.pm) {
                            return;
                        }

                        // ignore buffers, circles & rectangles
                        if ("Buffer" === layer.options.origin) {
                            return;
                        }

                        // ignore polygons
                        if (undefined !== layer._tooltip) {
                            return;
                        }

                        layer.remove();
                    });
                }
            },
            position: "bottomleft",
            width: 500,
        });

        return () => {
            actionArea.remove();
            actionBufferCircle.remove();
            actionBufferObjects.remove();
            actionDelete.remove();
            actionHeightgraph.remove();
            actionPolyline.remove();
        };
    }, []);

    const addButton = (title, icon, onActive, onInactive) => {
        const iconString = renderToString(icon);
        return L.easyButton({
            states: [
                {
                    stateName: "button-inactive",
                    icon: iconString,
                    title: title,
                    onClick: onInactive,
                },
                {
                    stateName: "button-active",
                    icon: iconString,
                    title: title,
                    onClick: onActive,
                },
            ],
        })
            .setPosition("bottomright")
            .addTo(map);
    };

    const clearDraw = () => {
        map.eachLayer((layer) => {
            if (null !== layer._path && undefined !== layer.pm) {
                layer.remove();
            }
        });
    };

    const createActionArea = () => {
        const elementId = "area-action";
        const onActionAreaBegin = (btn) => {
            initOnActionBegin(btn, elementId);

            // act upon action button click
            map.on("pm:drawstart", ({ workingLayer }) => {
                let lastClickLatLng = null;
                let clickedDistance = 0;
                // store lat/lng and add distance for clicked points
                workingLayer.on("pm:vertexadded", (event) => {
                    const newClickLatLng = event.latlng;
                    if (null !== lastClickLatLng) {
                        clickedDistance +=
                            lastClickLatLng.distanceTo(newClickLatLng);
                    }
                    lastClickLatLng = newClickLatLng;
                });
                // add distance from current mouse position
                map.on("mousemove", (event) => {
                    if (
                        null !== lastClickLatLng &&
                        undefined !== event.target._tooltip
                    ) {
                        const segmentDistance = lastClickLatLng.distanceTo(
                            event.latlng,
                        );
                        const totalDistance = clickedDistance + segmentDistance;
                        event.target.setTooltipContent(
                            getDistanceLabel(totalDistance, segmentDistance),
                        );
                    }
                });
            });

            // act upon polygon done
            map.on("pm:create", (event) => {
                const layer = event.layer;

                // ensure that newly created layers can be removed with Geoman's eraser
                layer.options.pmIgnore = false;
                L.PM.reInitLayer(layer);

                // calculate and show area
                if ("Polygon" === event.shape) {
                    const latLngs = layer.getLatLngs();
                    const area = L.GeometryUtil.geodesicArea(latLngs[0]);
                    const readableArea = L.GeometryUtil.readableArea(
                        area,
                        true,
                        2,
                    );
                    layer
                        .bindTooltip(readableArea, { permanent: true })
                        .openTooltip();
                }
                // disable action to allow for a quick restart
                onActionEnd(button, elementId);
            });

            map.pm.enableDraw("Polygon");
        };

        const button = createButton(
            "Area",
            <IconArea />,
            onActionAreaBegin,
            (btn) => onActionEnd(btn, elementId),
            elementId,
        );
        return button;
    };

    const createActionBufferCircle = () => {
        const elementId = "circle-buffer-action";
        const title = "Circle Buffer";
        const icon = <IconBuffer title={title} />;
        const button = addButton(
            title,
            icon,
            (btn) => {
                initOnActionEnd(btn, elementId);
                setIsActionBufferCircleActive(false);
            },
            (btn) => {
                initOnActionBegin(btn, elementId);
                setIsActionBufferCircleActive(true);
            },
        );

        // add additional logic to button
        const buttonContainer = document.querySelectorAll(
            ".leaflet-bottom.leaflet-right .easy-button-container",
        )[0];
        const container = document.createElement("div");
        container.id = elementId;
        container.className = "leaflet-pm-actions-container d-none";
        const action = document.createElement("div");
        action.className = "leaflet-pm-action";
        const a = document.createElement("a");
        a.className = "pe-2";
        a.textContent = `Radius (m)`;
        action.appendChild(a);
        const input = document.createElement("input");
        L.DomEvent.disableClickPropagation(input);
        input.className = "ps-1";
        input.maxLength = 4;
        input.onkeyup = () => {
            let newRadius = parseInt(input.value, 10);
            if (input.value !== "" && Number.isInteger(newRadius) === false) {
                input.value = bufferCircleRadius;
                newRadius = bufferCircleRadius;
            }

            setBufferCircleRadius(newRadius);
        };
        input.type = "text";
        input.value = bufferCircleRadius;
        action.appendChild(input);
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return button;
    };

    const createActionBufferObjects = () => {
        const elementId = "objects-buffer-action";
        const title = "Buffers";
        const button = addButton(
            title,
            <IconHouse title={title} />,
            (btn) => {
                initOnActionEnd(btn, elementId);
            },
            (btn) => {
                initOnActionBegin(btn, elementId);
            },
        );

        // add additional logic to button
        const buttonContainer = document.getElementsByClassName(
            "easy-button-container",
        )[1];
        const container = document.createElement("div");
        container.id = elementId;
        container.className = "leaflet-pm-actions-container d-none";
        const action = document.createElement("div");
        action.className = "leaflet-pm-action";
        [
            {
                color: "#FF7518",
                height: 3,
                title: "JBox",
                width: 10,
            },
            {
                color: "#FF4433",
                height: 10,
                title: "JBox Bail",
                width: 10,
            },
            {
                color: "#FA8072",
                height: 25,
                title: "IECharge Commune",
                width: 10,
            },
            {
                color: "#FFAA33",
                height: 27,
                title: "IECharge Département",
                width: 11,
            },
        ].forEach((element) => {
            const a = document.createElement("a");
            a.className = "d-block";
            a.textContent = element.title;
            a.onclick = () => {
                // apparently this is the only way to set a fixed size polygon: draw circles and pick coordinates from boundaries
                const center = map.getCenter();
                const circleHeight = new L.circle(center, {
                    radius: element.height / 2,
                }).addTo(map);
                const circleHeightBounds = circleHeight.getBounds();
                map.removeLayer(circleHeight);
                const circleWidth = new L.circle(center, {
                    radius: element.width / 2,
                }).addTo(map);
                const circleWidthBounds = circleWidth.getBounds();
                map.removeLayer(circleWidth);
                L.rectangle(
                    [
                        [
                            circleHeightBounds.getNorthWest().lat,
                            circleWidthBounds.getNorthWest().lng,
                        ],
                        [
                            circleHeightBounds.getSouthEast().lat,
                            circleWidthBounds.getSouthEast().lng,
                        ],
                    ],
                    {
                        color: element.color,
                        origin: "Buffer",
                        pmIgnore: false,
                        weight: 2,
                    },
                ).addTo(map);
            };
            action.appendChild(a);
        });
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return button;
    };

    const createActionDelete = () => {
        return L.easyButton(
            renderToString(<IconTrash />),
            () => {
                clearDraw();
            },
            "Remove all",
        )
            .setPosition("bottomright")
            .addTo(map);
    };

    const createActionHeightgraph = () => {
        const elementId = "elevation-action";
        const onActionHeightgraphBegin = (btn) => {
            initOnActionBegin(btn, elementId);

            map.pm.enableDraw('Line');
            map.on('pm:create', event => {
                const layer = event.layer;

                // ensure that newly created layers can be removed with Geoman's eraser
                layer.options.pmIgnore = false;
                L.PM.reInitLayer(layer);

                if ('Line' === event.shape) {
                    // convert clicked points to geodesic line, i.e. extrapolating their lat/lng to a mappable line
                    const coordinates = (new L.geodesic(layer.getLatLngs())).getLatLngs()[0];

                    drawHeightgraph(coordinates);
                    onActionHeightgraphEnd(btn);
                }
            });
        };
        const onActionHeightgraphEnd = (btn) => {
            initOnActionEnd(btn, elementId);

            map.pm.disableDraw();
            map.off("pm:drawend");
        };

        return createButton(
            "Elevation",
            <IconElevation />,
            onActionHeightgraphBegin,
            onActionHeightgraphEnd,
            elementId,
        );
    };

    const createActionPolyline = () => {
        const elementId = "polyline-action";
        const onActionPolylineBegin = (btn) => {
            initOnActionBegin(btn, elementId);

            let clickedDistance;

            // act upon action button click
            map.on("pm:drawstart", ({ workingLayer }) => {
                let lastClickLatLng = null;
                clickedDistance = 0;
                // store lat/lng and add distance for clicked points
                workingLayer.on("pm:vertexadded", (event) => {
                    const newClickLatLng = event.latlng;
                    if (null !== lastClickLatLng) {
                        clickedDistance +=
                            lastClickLatLng.distanceTo(newClickLatLng);
                    }
                    lastClickLatLng = newClickLatLng;
                });
                // add distance from current mouse position
                map.on("mousemove", (event) => {
                    if (
                        null !== lastClickLatLng &&
                        undefined !== event.target._tooltip
                    ) {
                        const segmentDistance = lastClickLatLng.distanceTo(
                            event.latlng,
                        );
                        const totalDistance = clickedDistance + segmentDistance;
                        event.target._tooltip._contentNode.classList.add(
                            "tooltip-distance",
                        ); // ugly but does the job, not sure where that tooltip is initialized
                        event.target.setTooltipContent(
                            getDistanceLabel(totalDistance, segmentDistance),
                        );
                    }
                });
            });

            // act upon polygon done
            map.on("pm:create", (event) => {
                const layer = event.layer;

                // ensure that newly created layers can be removed with Geoman's eraser
                layer.options.pmIgnore = false;
                L.PM.reInitLayer(layer);

                if ("Line" === event.shape) {
                    layer
                        .bindTooltip(getDistanceLabel(clickedDistance), {
                            className: "tooltip-distance",
                            permanent: true,
                        })
                        .openTooltip();
                }
                // disable action to allow for a quick restart
                onActionEnd(button, elementId);
            });

            map.pm.enableDraw("Line");
        };

        const button = createButton(
            "Distance",
            <IconDistance />,
            onActionPolylineBegin,
            (btn) => onActionEnd(btn, elementId),
            elementId,
        );
        return button;
    };

    const createButton = (
        title,
        icon,
        onActionBegin,
        onActionEnd,
        elementId,
    ) => {
        const button = addButton(title, icon, onActionEnd, onActionBegin);
        const buttonContainer = document.getElementsByClassName(
            "easy-button-container",
        )[1];
        const container = document.createElement("div");
        container.id = elementId;
        container.className = "leaflet-pm-actions-container d-none";
        buttonContainer.appendChild(container);
        return button;
    };

    const drawCircle = (event, radius) => {
        L.circle(event.latlng, {
            origin: "Buffer",
            pmIgnore: false,
            radius: 1,
        }).addTo(circleFeatureGroup); // this is a hack to mark the center of the circle... through another circle :/
        L.circle(event.latlng, {
            origin: "Buffer",
            pmIgnore: false,
            radius: radius,
        }).addTo(circleFeatureGroup);
        circleFeatureGroup.bringToFront();
    };

    const drawHeightgraph = (latLngs) => {
        elevationService.get(latLngs).then((response) => {
            if (response === null) {
                alertService.error("Problems with obtaining elevation data");
                return;
            }

            const elevations = [];
            let distance = 0; // in m, needed for slope calculation
            let prevCoordinate = null;

            const coordinates = response.map((data) => {
                let coordinate = [data.location.lat, data.location.lng];
                if (prevCoordinate !== null) {
                    distance += map.distance(prevCoordinate, coordinate);
                }
                prevCoordinate = coordinate;

                elevations.push(data.elevation);
                return [
                    data.location.lng,
                    data.location.lat,
                    Number(data.elevation.toFixed(2)),
                ];
            });

            let elevationTotal = 0;
            elevations.forEach((elevation, index) => {
                if (index !== elevations.length - 1) {
                    elevationTotal += Math.abs(
                        elevations[index + 1] - elevations[index],
                    );
                }
            });

            const slopePercentage = (elevationTotal / distance) * 100;
            const data = [
                {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: {
                                type: "LineString",
                                coordinates: coordinates,
                            },
                            properties: {
                                attributeType: "",
                            },
                        },
                    ],
                    properties: {
                        Creator: "Google Elevation API",
                        records: 1,
                        summary: `Slope Percentage: ${slopePercentage.toFixed(2)}%`,
                    },
                },
            ];

            heightgraph.addTo(map);
            heightgraph.addData(data);

            // bottom left align
            const panel = document.getElementById("panel");
            const left =
                true === panel.classList.contains("hiding")
                    ? 0
                    : panel.offsetWidth;
            document.querySelector(".heightgraph").style.left = `${left}px`;
        });
    };

    const getDistanceLabel = (totalDistance, segmentDistance = null) => {
        let unit = "m";
        if (1000 < totalDistance) {
            unit = "km";
            totalDistance /= 1000;
            if (null !== segmentDistance) {
                segmentDistance /= 1000;
            }
        }

        const segmentDistanceString =
            null !== segmentDistance
                ? `<div class="polyline-measure-tooltip-difference">+${segmentDistance.toFixed(2)} ${unit}</div>`
                : "";
        const totalDistanceString = `<div class="polyline-measure-tooltip-total">${totalDistance.toFixed(2)} ${unit}</div>`;
        return segmentDistanceString + totalDistanceString;
    };

    const initOnActionBegin = (btn, elementId) => {
        mapInteractionDisable(map);
        btn.state("button-active");
        const container = document.getElementById(elementId);
        container.classList.remove("d-none");
    };

    const initOnActionEnd = (btn, elementId) => {
        mapInteractionEnable(map);
        btn.state("button-inactive");
        const container = document.getElementById(elementId);
        container.classList.add("d-none");
    };

    const onActionEnd = (btn, elementId) => {
        initOnActionEnd(btn, elementId);

        map.pm.disableDraw();
        map.off("mousemove");
        map.off("pm:create");
        map.off("pm:drawstart");
        map.off("pm:vertexadded");
    };
}
